export enum Routes {
  HOME = '/app/home',
  LIST_USERS = '/app/person',
  LIST_CHOFER_CARRO= '/app/person/chofer_carro',
  ADD_USERS = '/app/person/add',
  EDIT_USERS = '/app/person/edit',
  FILE_USERS = '/app/person/file',
  ADRESS_USERS = '/app/person/adress',
  DOC_SERV_DRIVER = '/app/person/docserv',
  LOGIN = '/login',
  TRAZAS = 'traces',
  LIST_OPERATION = '/app/wallet',
  LIST_OPER_GENERALES = '/app/wallet/operations',
  ADD_OPERATION = '/app/wallet/add',
  EDIT_OPERATION = '/app/wallet/edit',
  LIST_EXPENSE = '/app/wallet/expense',
  ADD_EXPENSE = '/app/wallet/expense/add',
  EDIT_EXPENSE = '/app/wallet/expense/edit',
  LIST_PAYMENT = '/app/wallet/payments',
  ADD_PAYMENT = '/app/wallet/payments/add',
  EDIT_PAYMENT = '/app/wallet/payments/edit',
  LIST_SERVICES = '/app/services',
  ADD_SERVICES = '/app/services/add',
  EDIT_SERVICES = '/app/services/edit',
  LIST_INFORMES = '/app/informes',
  TURN_HISTORY = '/app/turn/history',
  CLOSE_TURN = '/app/turn/close',
  EDIT_TURN = '/app/turn/edit',
  TURN_LIST_BONOS = '/app/turn/bonos',
  TURN_ADD_BONO = '/app/turn/add_bono',
  TURN_EDIT_BONO = '/app/turn/edit_bono',
  LIST_NOTIFICATION = '/app/notification',
  ADD_NOTIFICATION = '/app/notification/add',
  EDIT_NOTIFICATION = '/app/notification/edit',
  LIST_PROG_NOTIFICACION = '/app/notification/programadas',
  LIST_INCIDENT = '/app/incidents',
  ADD_INCIDENT = '/app/incidents/add',
  EDIT_INCIDENT = '/app/incidents/edit',
  LIST_TERRITORIAL_FRAME = '/app/map/territorial_frame',
  ADD_TERRITORIAL_FRAME = '/app/map/territorial_frame/add',
  EDIT_TERRITORIAL_FRAME = '/app/map/territorial_frame/edit',
  LIST_SYSTEM_CONFIG= '/app/administracion/conf_sistema',
  ADD_SYSTEM_CONFIG= '/app/administracion/add_conf_sistema',
  ADD_PRICE_CONFIG= '/app/precio/add_conf_precio',
  LIST_PRICE_CONFIG= '/app/precio/conf_precio',
  LIST_TURNO_CONFIG= '/app/administracion/conf_cap_turno',
  ADD_TURNO_CONFIG= '/app/administracion/add_cap_turno',
  LIST_HORCALC_CONFIG= '/app/precio/conf_horario_calc',
  ADD_HORCALC_CONFIG= '/app/precio/add_horario_calc',
  LIST_DOCCHOFER_CONFIG= '/app/administracion/conf_doc_chofer',
  ADD_DOCCHOFER_CONFIG= '/app/administracion/add_doc_chofer',
  LIST_DIASFEST_CONFIG= '/app/precio/conf_dias_festivos',
  ADD_DIASFEST_CONFIG= '/app/precio/add_dias_festivos',
  LIST_FACTORVIAJE_CONFIG= '/app/precio/conf_factor_viaje',
  ADD_FACTORVIAJE_CONFIG= '/app/precio/add_factor_viaje',
  ADD_CADECA_CONFIG= '/app/precio/add_cadeca',
  LIST_CADECA_CONFIG= '/app/precio/conf_cadeca',
  LIST_CATEGORIA_CONFIG= '/app/administracion/conf_categoria',
  ADD_CATEGORIA_CONFIG= '/app/administracion/add_categoria',
  LIST_NOMENCLATOR_TIPO_GASTO= '/app/nomenclators/nomenclador_tipo_gasto',
  ADD_NOMENCLATOR_TIPO_GASTO= '/app/nomenclators/add_nomenclador_tipo_gasto',
  LIST_NOMENCLATOR_MARCA_AUTO= '/app/nomenclators/nomenclador_marca_auto',
  ADD_NOMENCLATOR_MARCA_AUTO= '/app/nomenclators/add_nomenclador_marca_auto',
  LIST_PRECIO_MIN_CONFIG= '/app/precio/conf_precio_minimo',
  ADD_PRECIO_MIN_CONFIG= '/app/precio/add_precio_minimo',
  LIST_NOM_MARCA_MODELO_AUTO= '/app/nomenclators/nom_marca_modelo_auto',
  ADD_NOM_MARCA_MODELO_AUTO= '/app/nomenclators/add_nom_marca_modelo_auto',
  LIST_NOMENCLATOR_TIPO_INCIDENCIA= '/app/nomenclators/nomenclador_tipo_incidencia',
  ADD_NOMENCLATOR_TIPO_INCIDENCIA= '/app/nomenclators/add_nomenclador_tipo_incidencia',
  LIST_NOMENCLATOR_PAIS= '/app/nomenclators/nomenclador_pais',
  ADD_NOMENCLATOR_PAIS= '/app/nomenclators/add_nomenclador_pais',
  LIST_NOMENCLATOR_CAUSA_CANCELACION= '/app/nomenclators/nomenclador_causa_cancelacion',
  ADD_NOMENCLATOR_CAUSA_CANCELACION= '/app/nomenclators/add_nomenclador_causa_cancelacion',
  LIST_NOMENCLATOR_TIPO_INC_CLASIF = '/app/nomenclators/nomenclador_tipo_incidencia_clasificacion',
  ADD_NOMENCLATOR_TIPO_INC_CLASIF = '/app/nomenclators/add_nomenclador_tipo_incidencia_clasificacion',
  LIST_NOMENCLATOR_VERSION_APP = '/app/nomenclators/nomenclador_version_app',
  EDIT_NOMENCLATOR_VERSION_APP = '/app/nomenclators/edit_nomenclador_version_app',
  LIST_NOMENCLATOR_TIPO_ALERTA = '/app/nomenclators/nomenclador_tipo_alerta',
  ADD_NOMENCLATOR_TIPO_ALERTA = '/app/nomenclators/add_nomenclador_tipo_alerta',
  LIST_NOMENCLATOR_AREA = '/app/nomenclators/nomenclador_area',
  ADD_NOMENCLATOR_AREA = '/app/nomenclators/add_nomenclador_area',
  LIST_NOMENCLATOR_CLASIF_ACTIVO_FIJO = '/app/nomenclators/nomenclador_clasificacion_activo_fijo',
  ADD_NOMENCLATOR_CLASIF_ACTIVO_FIJO = '/app/nomenclators/add_nomenclador_clasificacion_activo_fijo',
  LIST_PLANTILLA_TURNOS = '/app/turn/plantilla_turnos',
  ADD_PLANTILLA_TURNOS = '/app/turn/add_plantilla_turnos',
  LIST_PRECIO_KM = '/app/precio/precio_xkm',
  ADD_PRECIO_KM = '/app/precio/add_precio_xkm',
  LIST_PRECIO_KM_PROVINCIA = '/app/precio/precio_km_provincia',
  ADD_PRECIO_KM_PROVINCIA = '/app/precio/add_precio_km_provincia',
  LIST_PRECIO_MARCO_TERRITORIAL = '/app/precio/precio_marco_territorial',
  ADD_PRECIO_MARCO_TERRITORIAL = '/app/precio/add_precio_marco_territorial',
  LIST_CUENTA_AGENCIA = '/app/wallet/cuentas_agencia',
  ADD_CUENTA_AGENCIA = '/app/wallet/add_cuenta_agencia',
  LIST_ANTIGUEDAD_CONFIG = '/app/administracion/conf_antiguedad',
  ADD_ANTIGUEDAD_CONFIG = '/app/administracion/add_antiguedad',
  LIST_CAR_INSPECTION = '/app/person/inspeccion_auto',
  ADD_CAR_INSPECTION = '/app/person/add_inspeccion_auto',
  LIST_ACTIVO_FIJO = '/app/inventario/activo_fijo',
  ADD_ACTIVO_FIJO = '/app/inventario/add_activo_fijo',
  LIST_UTIL_HERRAMIENTA = '/app/inventario/utiles_herramientas',
  ADD_UTIL_HERRAMIENTA = '/app/inventario/add_utiles_herramientas',
  LIST_UBICACION = '/app/inventario/ubicacion',
  ADD_UBICACION = '/app/inventario/add_ubicacion',
  LIST_ALMACEN_PRODUCTOS = '/app/inventario/almacen_productos',
  EDIT_ALMACEN_PRODUCTOS = '/app/inventario/edit_almacen_productos',
  LIST_ALMACEN_ENTRADAS = '/app/inventario/almacen_entradas',
  LIST_ALMACEN_SALIDAS = '/app/inventario/almacen_salidas',
  ADD_ALMACEN_ENTRADA = '/app/inventario/add_almacen_entrada',
  ADD_ALMACEN_SALIDA = '/app/inventario/add_almacen_salida',
  LIST_NOMENCLATOR_ESTADO_INCIDENCIA = '/app/nomenclators/nomenclador_estado_incidencia',
  ADD_NOMENCLATOR_ESTADO_INCIDENCIA = '/app/nomenclators/add_nomenclador_estado_incidencia',
  ADD_PEDIDO = '/app/paqueteria/add_pedido',
  EDIT_PEDIDO = '/app/paqueteria/edit_pedido',
  EDIT_ENVIO = '/app/paqueteria/edit_envio',
  LIST_PEDIDOS_PENDING = '/app/paqueteria/pedidos/pending',
  LIST_ENVIOS_NEW = '/app/paqueteria/envios/new',
  LIST_NOMENCLATOR_MODO_PAGO = '/app/nomenclators/nomenclador_modo_pago',
  EDIT_NOMENCLATOR_MODO_PAGO = '/app/nomenclators/edit_nomenclador_modo_pago',
}
