import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RouteService} from "../../services/route.service";
import {NgxUiLoaderService} from "ngx-ui-loader";
import {PersonService} from "../../../features/users/services/persons.service";
import {NotificationService} from "../../services/notification.service";
import {PersonEntity, PersonRequest} from "../../models/person-model";

@Component({
  selector: 'app-create-shift-dialog',
  templateUrl: './create-shift-dialog.component.html',
  styleUrls: ['./create-shift-dialog.component.scss']
})
export class CreateShiftDialogComponent implements OnInit, OnDestroy{

  title: string;
  message: string;
  confirmBottom: boolean;
  confirmLabelBottom: string;
  dismissBottom:boolean;
  dismissLabelBottom:string;

  idJefeTurno!: number;
  listJefeTurno: any; // PersonEntity[] = [];

  closeModalSubscription: Subscription;

  constructor(public dialogRef: MatDialogRef<CreateShiftDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ShiftDialogModel, private personService: PersonService,
              private routeService: RouteService, protected ngxLoaderService: NgxUiLoaderService,
              private notificacionService: NotificationService) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;

    this.confirmBottom = data.confirmBottom;
    this.confirmLabelBottom = data.confirmLabelBottom;
    this.dismissBottom = data.dismissBottom;
    this.dismissLabelBottom = data.dismissLabelBottom;

    this.closeModalSubscription = this.routeService.closeModals$.subscribe(closeModal => {
      if (closeModal) {
        this.dialogRef.close(false);
      }
    });
  }

  ngOnInit(): void {
    this.ngxLoaderService.start();
    this.personService.getJTurnPersons().subscribe({
      next: (res) => {
        console.log(res);
        this.ngxLoaderService.stop();

        this.listJefeTurno = res;
      },
      error: (err) => {
        this.ngxLoaderService.stop();
        this.notificacionService.notificationError(
          'Lo sentimos, ocurrió un error al cargar la persona'
        );
      },
    });
  }

  ngOnDestroy(): void {
    if (this.closeModalSubscription){
      this.closeModalSubscription.unsubscribe();
    }
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close({result: true, idJefeTurno: this.idJefeTurno});
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close({result: false});
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ShiftDialogModel {

  constructor(public title: string, public message: string,
              public confirmBottom: boolean, public confirmLabelBottom: string,
              public dismissBottom: boolean, public dismissLabelBottom: string) {
  }
}
