const BASE_URL = "http://147.182.204.223:7000"
const BASE_TRACE_URL = "http://147.182.204.223:7000"
const LOCAL_URL = "http://192.168.2.10:7000"

export const environment = {
  production: true,

  /**URL Origin Service */
  urlOrigin: BASE_URL,
  localUrl: LOCAL_URL,

  /** Login */
  serviceLogin: BASE_URL + '/rt_cs_api/login',
  serviceRefreshLogin: BASE_URL + '/rt_cs_api/refresh_token',
  serviceLogout: BASE_URL + '/rt_cs_api/logout',
  serviceCategories: BASE_URL + '/rt_cs_api/categorias',
  serviceProvinces: BASE_URL + '/rt_cs_api/provincias',
  serviceCreateShift: BASE_URL + '/rt_cs_api/turno_sesion',
  serviceTurnoActivo: BASE_URL + '/rt_cs_api/turnos2',
  serviceFinalBalancePreviousShift: BASE_URL + '/rt_cs_api/turnos2/ultimo_saldo_final',
  serviceMovilAccount: BASE_URL + '/rt_cs_api/turnos/cuentas_moviles',
  serviceSessions: BASE_URL + '/rt_cs_api/sesiones',

  /** Personas */
  servicePerson: BASE_URL + '/rt_cs_api/personas',
  serviceJTurnPerson: BASE_URL + '/rt_cs_api/personas/jefes_turno',
  serviceTraces: BASE_URL +  '/rt_cs_api/trazas',
  serviceTracesHistory: BASE_URL + '/rt_cs_api/trazas_hist',
  serviceImagenes: BASE_URL + '/rt_cs_api/imagenes',
  serviceClient: BASE_URL + '/rt_cs_api/clientes',
  serviceDrive: BASE_URL + '/rt_cs_api/choferes',
  servicePaisNomenTelef: BASE_URL + '/rt_cs_api/nomenclator/paises',
  serviceAccreditor: BASE_URL + '/rt_cs_api/acreditadores',
  servicePersonEntity: BASE_URL + '/rt_cs_api/persona_entidad',
  servicePersonVinculadaEntity: BASE_URL + '/rt_cs_api/persona_vinculada_entidad',
  serviceCarInspection: BASE_URL + '/rt_cs_api/chofer_inspeccion',
  serviceMembership: BASE_URL + '/rt_cs_api/personas/miembros_membresia',
  serviceMemberHistory: BASE_URL +'/rt_cs_api/persona_historial_membresia',
  serviceRenewMembership: BASE_URL + '/rt_cs_api/personas/renovar_miembro_membresia',
  serviceCancelMembership: BASE_URL + '/rt_cs_api/personas/cancelar_subcripcion_membresia',
  serviceFrequentSites: BASE_URL + '/rt_cs_api/direcciones',
  serviceBirthay: BASE_URL + '/rt_cs_api/persona/mensaje_cumple_annos',

  /** Autos,Servicios y Documentos del chofer */
  serviceMarks: BASE_URL + '/rt_cs_api/nomenclator/nom_marcas_auto',
  serviceColors: BASE_URL + '/rt_cs_api/colores',
  serviceAllServices: BASE_URL + '/rt_cs_api/administracion/factores_viaje',
  serviceConfDoc: BASE_URL + '/rt_cs_api/administracion/config_doc_chofer',
  serviceNomModulo: BASE_URL + '/rt_cs_api/nomenclator/nom_modulo',

  /** Billetera */
  serviceOperation: BASE_URL + '/rt_cs_api/operaciones',
  serviceExpense: BASE_URL + '/rt_cs_api/gastos',
  serviceOperationType: BASE_URL + '/rt_cs_api/tipos_operacion',
  servicePaymentMode: BASE_URL + '/rt_cs_api/nomenclator/nom_modo_pago',
  serviceCuentas: BASE_URL + '/rt_cs_api/cuentas',
  serviceCuentaAgencia: BASE_URL + '/rt_cs_api/cuenta_agencia',
  serviceAllOperation: BASE_URL + '/rt_cs_api/operaciones_todas',
  serviceUserPayment: BASE_URL + '/rt_cs_api/usuario_pago_realizado',
  serviceTipoDeuda: BASE_URL + '/rt_cs_api/nomenclator/nom_tipo_deuda',
  serviceCadecaHistory: BASE_URL + '/rt_cs_api/precio/calc_cadeca_historial',
  serviceOperationCobrosPagos: BASE_URL + '/rt_cs_api/operaciones_cobros_pagos',
  serviceOperationsDeudas: BASE_URL + '/rt_cs_api/operaciones/union-operaciones-deudas',
  serviceDepositoExterior: BASE_URL + '/rt_cs_api/api_externas/pago_externo',
  serviceDeudasMonedas: BASE_URL + '/rt_cs_api/deudas_pagos_anticipado',
  serviceTransferirCuentaAgencia: BASE_URL + '/rt_cs_api/operaciones/cuentas_agencia',

  /** Servicios */
  serviceService: BASE_URL + '/rt_cs_api/viajes',
  serviceServiceHistoric: BASE_URL + '/rt_cs_api/viajes_hist',
  serviceCommunicationWay: BASE_URL + '/rt_cs_api/nomenclator/nom_via_comunicacion',
  serviceConfigComision: BASE_URL + '/rt_cs_api/administracion/config_comision',
  serviceCauseCancel: BASE_URL + '/rt_cs_api/nomenclator/nom_causa_cancelacion',
  serviceTravelType: BASE_URL + '/rt_cs_api/nomenclator/nom_tipo_viaje',
  serviceCalculatePrice: BASE_URL + '/rt_cs_api/viajes/calcular_precio',
  serviceGenerateCod: BASE_URL + '/rt_cs_api/viajes/generar_cod_viaje',
  serviceCounts: BASE_URL + '/rt_cs_api/viajes/cantidades_para_tabs',
  serviceTravelState: BASE_URL + '/rt_cs_api/nomenclator/nom_estado_viaje',

  /**Informes */
  serviceInformes: BASE_URL + '/rt_cs_api/informe/informes',
  serviceTemasInformes: BASE_URL + '/rt_cs_api/informe/informe_temas',
  serviceCierreContable: BASE_URL + '/rt_cs_api/cierre_contable',
  serviceCierreContableDiario: BASE_URL + '/rt_cs_api/cierre_contable_diario',
  serviceGastoContable: BASE_URL + '/rt_cs_api/gastos/contable',

  /**Notificaciones */
  serviceNotificationsPush: BASE_URL + '/rt_cs_api/notificaciones_push',
  serviceNotifications: BASE_URL + '/rt_cs_api/notificaciones',
  serviceNotificationsSuscribir: BASE_URL + '/rt_cs_api/alertas/subscribir',

  /**Configuraciones */
  serviceConfigurations: BASE_URL + '/rt_cs_api/administracion/config_variable',

   /**Incidencias */
   serviceIncidencias: BASE_URL + '/rt_cs_api/incidencias',
   serviceTipoIncidencias: BASE_URL + '/rt_cs_api/nomenclator/nom_tipo_incidencia',
   serviceClaasifTipoIncidencias: BASE_URL + '/rt_cs_api/nomenclator/nom_tipo_incidencia_clasificacion',

  /**Precios*/
  servicePrecio: BASE_URL + '/rt_cs_api/precio/calc_precio_x_km_ref',
  servicePrecioKmProvincia: BASE_URL + '/rt_cs_api/precio/precio_km_provinca',
  servicePrecioMarcoTerritorial: BASE_URL + '/rt_cs_api/precio/calc_entre_marcos_ter',

  /**Administracion */
  serviceConfigVariable: BASE_URL + '/rt_cs_api/administracion/config_variable',
  serviceConfigTurno: BASE_URL + '/rt_cs_api/administracion/config_turno',
  serviceConfigHorCalc: BASE_URL + '/rt_cs_api/administracion/config_horario',
  serviceConfigDocChofer: BASE_URL + '/rt_cs_api/administracion/config_doc_chofer',
  serviceConfigDiaFestivo: BASE_URL + '/rt_cs_api/administracion/config_dias_festivos',
  serviceConfigFactorViaje: BASE_URL + '/rt_cs_api/administracion/factores_viaje',
  serviceNomencladorTipoGasto:  BASE_URL + '/rt_cs_api/nomenclator/nom_tipo_gasto',
  serviceNomencladorMarcaAuto: BASE_URL + '/rt_cs_api/nomenclator/nom_marcas_auto',
  serviceNomencladorMarcaModeloAuto: BASE_URL + '/rt_cs_api/nomenclator/nom_modelo_marcas_auto',
  serviceConfigCadeca: BASE_URL + '/rt_cs_api/precio/calc_cadeca',
  serviceRoles: BASE_URL + '/rt_cs_api/roles',
  servicePrecioMinFactores: BASE_URL + '/rt_cs_api/precio/calc_precios_minimos',
  serviceConfigAntiguedad: BASE_URL + '/rt_cs_api/administracion/config_antiguedad',
  serviceNomencladorVersionApk: BASE_URL + '/rt_cs_api/nomenclator/nom_version_minima_app',
  serviceNomencladorTipoAlerta: BASE_URL + '/rt_cs_api/nomenclator/nom_tipo_alerta',
  serviceNomencladorEstadoIncidencia: BASE_URL + '/rt_cs_api/nomenclator/nom_estado_incidencia',
  serviceNomencladorModoPago: BASE_URL + '/rt_cs_api/nomenclator/nom_modo_pago',
  serviceAccionRol: BASE_URL + '/rt_cs_api/accion/relacion_accion_rol',

  /**Inventario*/
  serviceActivoFijo: BASE_URL + '/rt_cs_api/inventario/activo_fijo',
  serviceUtilesHerramientas: BASE_URL + '/rt_cs_api/inventario/utiles_herramientas',
  serviceDocuments: BASE_URL + '/rt_cs_api/documento_modulo',
  serviceNomencladorInvArea: BASE_URL + '/rt_cs_api/inventario/nomencladores/inv_nom_area',
  serviceNomencladorInvClasifActivoFijo: BASE_URL + '/rt_cs_api/inventario/nomencladores/inv_nom_tipo_producto',
  serviceNomencladorInvMotivoBaja: BASE_URL + '/rt_cs_api/inventario/nomencladores/inv_nom_motivo_baja',
  serviceInventarioUbicacion: BASE_URL + '/rt_cs_api/inventario/nomencladores/ubicacion',
  serviceAlmacenProducto: BASE_URL + '/rt_cs_api/almacen/producto',
  serviceAlmacenEntrada: BASE_URL + '/rt_cs_api/almacen/entrada',
  serviceAlmacenSalida: BASE_URL + '/rt_cs_api/almacen/salida',

  /**Turnos*/
  servicePlantillaTurnos: BASE_URL + '/rt_cs_api/turnos_plant',
  serviceGestionarBono: BASE_URL + '/rt_cs_api/turno_gasto',
  serviceCalendar: BASE_URL + '/rt_cs_api/calendario',
  serviceCalendarCategories: BASE_URL + '/rt_cs_api/categorias/usuarios_oficina',
  serviceCalendarPersons: BASE_URL + '/rt_cs_api/personas/usuario_oficinas',
  serviceProcessExcel: BASE_URL + '/rt_cs_api/calendario/procesarExcel',

  /**Marcos Territoriales */
  serviceTerritorialFrames: BASE_URL + '/rt_cs_api/marco_territorial',
  serviceTerritorialFrameType: BASE_URL + '/rt_cs_api/nomenclator/nom_tipo_marco_territ',

  /**Paquetería*/
  servicePaqueteriaPedido: BASE_URL + '/rt_cs_api/paqueteria/pedido',
  servicePaqueteriaEnvio: BASE_URL + '/rt_cs_api/paqueteria/envio',
  servicePaqueteriaNomencladorPedido: BASE_URL + '/rt_cs_api/paqueteria/nomencladores/tipo_pedido',
  servicePaqueteriaNomencladorEstadoEnvio: BASE_URL + '/rt_cs_api/paqueteria/nomencladores/estado_envio',
  servicePaqueteriaNomencladorAuto: BASE_URL + '/rt_cs_api/paqueteria/nomencladores/tipo_auto',
  servicePaqueteriaNomencladorCancel: BASE_URL + '/rt_cs_api/paqueteria/nomencladores/causa_cancel_envio',

  serviceGoogle: BASE_URL + '/rt_cs_api/api_google_maps',

  graphqlURL: BASE_URL + '/graphql',

};


